import type { DependencyList } from "react";
import React from "react";
/**
 * This hook is a temporary solution for data loading in the portal components.
 * This is likely to be removed and replaced with a different solution in the future.
 * As such, you shouldn't move this out of portal-design-system, or copy paste this anywhere.
 * @param queryFn A async function that executes the query and returns the data.
 * @param dependencies A list of values this query depends on. If any of these values changes, the query will run again.
 */
export function useExperimentalQuery<T>(queryFn: () => Promise<T>, dependencies: DependencyList): QueryResult<T> {
    const [queryState, dispatch] = React.useReducer<QueryStateReducer<T>>(queryStateReducer, { isLoading: true, error: undefined, data: undefined });
    const latestQueryTimestamp = React.useRef<number>();
    const latestQueryFn = React.useRef(queryFn);
    latestQueryFn.current = queryFn;
    const executeQuery = async () => {
        dispatch({ type: "loading" });
        const queryTimestamp = Date.now();
        latestQueryTimestamp.current = queryTimestamp;
        try {
            const data = await latestQueryFn.current();
            if (latestQueryTimestamp.current === queryTimestamp) {
                dispatch({ type: "success", data });
            }
        }
        catch (e) {
            if (e instanceof Error) {
                dispatch({ type: "error", error: e });
            }
            else {
                dispatch({ type: "error", error: new Error(`Unknown error: ${e}`) });
            }
        }
    };
    React.useEffect(() => {
        executeQuery();
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies);
    const refetch = React.useCallback(() => {
        executeQuery();
    }, []);
    const queryResult = React.useMemo(() => ({
        ...queryState,
        refetch,
    }), [queryState, refetch]);
    return queryResult;
}
type QueryAction<T> = {
    type: "loading";
} | {
    type: "success";
    data: T;
} | {
    type: "error";
    error: Error;
};
type QueryStateReducer<T> = React.Reducer<QueryState<T>, QueryAction<T>>;
const queryStateReducer = <T>(state: QueryState<T>, action: QueryAction<T>): QueryState<T> => {
    switch (action.type) {
        case "loading": {
            return {
                isLoading: true,
                error: undefined,
                data: state.data,
            };
        }
        case "success": {
            return {
                isLoading: false,
                error: undefined,
                data: action.data,
            };
        }
        case "error": {
            return {
                isLoading: false,
                error: action.error,
                data: state.data,
            };
        }
    }
};
export interface QueryLoadingState<T> {
    isLoading: true;
    error: undefined;
    data: T | undefined;
}
export interface QuerySuccessState<T> {
    isLoading: false;
    error: undefined;
    data: T;
}
export interface QueryErrorState<T> {
    isLoading: false;
    error: Error;
    data: T | undefined;
}
export type QueryState<T> = QueryLoadingState<T> | QuerySuccessState<T> | QueryErrorState<T>;
export type QueryResult<T> = QueryState<T> & {
    refetch: () => void;
};
