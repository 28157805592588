import type { Client, RequestCorrelationContext } from "@octopusdeploy/octopus-server-client";
import { Repository } from "@octopusdeploy/octopus-server-client";
import React, { useRef } from "react";
import { useOctopusClient } from "./OctopusClientContext";
export type LoaderQueryState<LoaderData> = IsLoadingLoaderState | HasErrorsLoaderState | SuccessLoaderState<LoaderData>;
export function useLoaderQuery<LoaderData>(loader: (repository: Repository) => Promise<LoaderData>, correlationContext: RequestCorrelationContext): LoaderQueryState<LoaderData> {
    const client = useOctopusClient();
    // We capture all of these values from the first render of this hook to ensure they don't change throughout the lifetime of the hook
    const loaderQueryContext = useRef<LoaderQueryParams<LoaderData>>({
        client,
        correlationContext,
        loader,
    });
    const [queryState, setQueryState] = React.useState<LoaderQueryState<LoaderData>>({ isLoading: true, error: null, result: null });
    React.useEffect(() => {
        const abortController = new AbortController();
        // noinspection JSIgnoredPromiseFromCall
        executeQuery(loaderQueryContext.current, setQueryState, abortController.signal);
        return () => abortController.abort();
    }, []);
    return queryState;
}
interface LoaderQueryParams<LoaderData> {
    loader: (repository: Repository) => Promise<LoaderData>;
    client: Client;
    correlationContext: RequestCorrelationContext;
}
async function executeQuery<LoaderData>({ loader, client, correlationContext }: LoaderQueryParams<LoaderData>, setQueryState: (state: LoaderQueryState<LoaderData>) => void, abortSignal: AbortSignal) {
    try {
        const repository = new Repository(client, {
            correlationContext,
            abortSignal,
        });
        const result = await loader(repository);
        if (!abortSignal.aborted) {
            setQueryState({ result, error: null, isLoading: false });
        }
    }
    catch (error) {
        // This not only protects us from setting query state if we happen to have
        // cancelled the abort controller after the request has completed but before setting state,
        // but it also ensures that if we throw an exception as a result of cancelling a request, we don't end up setting state.
        // We currently have no reliable way of detecting this second case based on the error type,
        // but that doesn't really matter because we can check the abort signal instead.
        if (!abortSignal.aborted) {
            if (error instanceof Error) {
                setQueryState({ error, isLoading: false, result: null });
            }
            else {
                setQueryState({ error: new Error(`Unknown error: ${JSON.stringify(error)}`), isLoading: false, result: null });
            }
        }
    }
}
interface IsLoadingLoaderState {
    isLoading: true;
    error: null;
    result: null;
}
interface HasErrorsLoaderState {
    isLoading: false;
    error: Error;
    result: null;
}
interface SuccessLoaderState<LoaderData> {
    isLoading: false;
    error: null;
    result: LoaderData;
}
