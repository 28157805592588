export class ValidationError extends Error {
    constructor(errorMessage: string, public readonly fieldErrors: FieldErrors) {
        super(errorMessage);
    }
}
export type FieldErrors = {
    [fieldName: string]: string;
};
export function isValidationError(error: Error): error is ValidationError {
    return error instanceof ValidationError;
}
