import type { Logger } from "@octopusdeploy/logging";
import type { RequestCorrelationContext } from "@octopusdeploy/octopus-server-client";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import React, { useMemo, useState, useContext, createContext } from "react";
interface AllMutationStates {
    [mutationId: string]: MutationState;
}
type MutationState = IsExecutingMutationState | HasErrorsMutationState | IsNotExecutingMutationState;
type IsExecutingMutationState = {
    isExecuting: true;
    error: null;
};
type HasErrorsMutationState = {
    isExecuting: false;
    error: Error;
};
type IsNotExecutingMutationState = {
    isExecuting: false;
    error: null;
};
interface MutationActions {
    start(mutationId: string, logger: Logger): void;
    finish(mutationId: string, logger: Logger): void;
    error(mutationId: string, error: Error, logger: Logger): void;
    unmount(mutationId: string): void;
}
const allMutationStatesContext = createContext<AllMutationStates | null>(null);
const mutationActionsContext = createContext<MutationActions | null>(null);
const mutationCorrelationContext = createContext<RequestCorrelationContext | null>(null);
function useAllMutationState() {
    const allMutationStates = useContext(allMutationStatesContext);
    if (allMutationStates === null) {
        throw new Error("Could not find all mutation state");
    }
    return allMutationStates;
}
function useMutationActions() {
    const allMutationStates = useContext(mutationActionsContext);
    if (allMutationStates === null) {
        throw new Error("Could not find mutation actions");
    }
    return allMutationStates;
}
export function useMutationCorrelationContext() {
    const correlationContext = useContext(mutationCorrelationContext);
    if (correlationContext === null) {
        throw new Error("Could not find mutation correlation context");
    }
    return correlationContext;
}
export function MutationContextProvider({ children, correlationContext }: MutationContextProps) {
    const [mutationState, setMutationState] = useState<AllMutationStates>({});
    const mutationActions = useAllMutationActions(setMutationState);
    return (<allMutationStatesContext.Provider value={mutationState}>
            <mutationActionsContext.Provider value={mutationActions}>
                <mutationCorrelationContext.Provider value={correlationContext}>{children}</mutationCorrelationContext.Provider>
            </mutationActionsContext.Provider>
        </allMutationStatesContext.Provider>);
}
interface MutationContextProps {
    children: ReactNode;
    correlationContext: RequestCorrelationContext;
}
function useAllMutationActions(setMutationState: Dispatch<SetStateAction<AllMutationStates>>): MutationActions {
    return useMemo(() => ({
        start(mutationId: string, logger: Logger) {
            setMutationState((prev) => {
                const previousState = prev[mutationId] ?? initialMutationState;
                if (previousState.isExecuting) {
                    logger.warn("Starting a mutation that is already running. This could cause a race condition. Avoid starting the same mutation twice.");
                }
                return {
                    ...prev,
                    [mutationId]: { isExecuting: true, error: null },
                };
            });
        },
        finish(mutationId: string, logger: Logger) {
            setMutationState((prev) => {
                const previousState = prev[mutationId];
                if (previousState === undefined) {
                    logger.warn("Finishing a mutation that hasn't been started or has been unmounted.");
                    throw new Error("Finishing a mutation that hasn't been started or has been unmounted.");
                }
                if (!previousState.isExecuting) {
                    logger.warn("Finishing a mutation that isn't running. This could be a sign that a mutation is running multiple times concurrently, which is not valid.");
                }
                return {
                    ...prev,
                    [mutationId]: { isExecuting: false, error: null },
                };
            });
        },
        error(mutationId: string, error: Error, logger: Logger) {
            setMutationState((prev) => {
                const previousState = prev[mutationId];
                if (previousState === undefined) {
                    logger.warn("Failing a mutation that hasn't been started or has been unmounted.");
                    throw new Error("Failing a mutation that hasn't been started or has been unmounted.");
                }
                if (!previousState.isExecuting) {
                    logger.warn("Failing a mutation that isn't running. This could be a sign that a mutation is running multiple times concurrently, which is not valid.");
                }
                return {
                    ...prev,
                    [mutationId]: { isExecuting: false, error },
                };
            });
        },
        unmount(mutationId: string) {
            setMutationState((prev) => Object.fromEntries(Object.entries(prev).filter(([id]) => id !== mutationId)));
        },
    }), [setMutationState]);
}
export function useMutationStateForMutation(mutationId: string): [
    MutationState,
    MutationActionsForMutation
] {
    const allMutationStates = useAllMutationState();
    const mutationState = allMutationStates[mutationId] ?? initialMutationState;
    const mutationActions = useMutationActions();
    const mutationsForMutationId: MutationActionsForMutation = useMemo(() => ({
        start: (logger: Logger) => mutationActions.start(mutationId, logger),
        finish: (logger: Logger) => mutationActions.finish(mutationId, logger),
        unmount: () => mutationActions.unmount(mutationId),
        error: (error: Error, logger: Logger) => mutationActions.error(mutationId, error, logger),
    }), [mutationActions, mutationId]);
    return [mutationState, mutationsForMutationId];
}
export interface MutationActionsForMutation {
    start(logger: Logger): void;
    finish(logger: Logger): void;
    unmount(): void;
    error(error: Error, logger: Logger): void;
}
export interface AggregateMutationState {
    isExecutingAnyMutation: boolean;
    errors: Error[];
}
export function useAggregateMutationsState(): AggregateMutationState {
    const mutationState = useAllMutationState();
    const mutationStates = Object.values(mutationState);
    return {
        isExecutingAnyMutation: mutationStates.some((state) => state.isExecuting),
        errors: mutationStates.map((e) => e.error).filter((error): error is Error => error !== null),
    };
}
const initialMutationState: IsNotExecutingMutationState = { isExecuting: false, error: null };
