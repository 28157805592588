import type { Client } from "@octopusdeploy/octopus-server-client";
import React from "react";
const OctopusClientContext = React.createContext<Client | undefined>(undefined);
export interface OctopusClientProviderProps {
    client: Client;
    children: React.ReactNode;
}
export function OctopusClientProvider({ client, children }: OctopusClientProviderProps) {
    return <OctopusClientContext.Provider value={client}>{children}</OctopusClientContext.Provider>;
}
export function useOctopusClient() {
    const client = React.useContext(OctopusClientContext);
    if (client === undefined) {
        throw new Error("Client not provided via a OctopusClientProvider");
    }
    return client;
}
export function useOctopusPathResolver() {
    const client = React.useContext(OctopusClientContext);
    if (client === undefined) {
        throw new Error("Client not provided via a OctopusClientContext");
    }
    return client.resolve;
}
