import type { RequestCorrelationContext } from "@octopusdeploy/octopus-server-client";
import type { ReactNode } from "react";
import React, { createContext, useContext, useMemo, useState } from "react";
export function QueryContextProvider({ children, correlationContext }: {
    children: ReactNode;
    correlationContext: RequestCorrelationContext;
}) {
    const allQueryStatuses = useAllQueryStatuses();
    return (<allQueryStatusesContext.Provider value={allQueryStatuses}>
            <queryCorrelationContext.Provider value={correlationContext}>{children}</queryCorrelationContext.Provider>
        </allQueryStatusesContext.Provider>);
}
export function useAggregateQueryStatuses() {
    const { allQueryStatuses } = useAllQueryStatusContext();
    return {
        isLoadingAnyQuery: Object.values(allQueryStatuses)
            // periodic refreshes shouldn't be shown in a central location
            // These are used for "live" results, and should have another mechanism for showing that the result is live (e.g. a text description, animations, etc.)
            .filter((s) => s.trigger !== "periodic")
            .some((v) => v.isLoading),
        errors: Object.values(allQueryStatuses)
            .map((s) => s.error)
            .filter((s) => s !== null),
    };
}
export function useQueryStatusUpdaters(queryId: string): QueryStatusUpdaters {
    const { allQueryStatusUpdaters } = useAllQueryStatusContext();
    return useMemo(() => ({
        start(trigger: "periodic" | "other") {
            allQueryStatusUpdaters.start(queryId, trigger);
        },
        error(error: Error) {
            allQueryStatusUpdaters.error(queryId, error);
        },
        finish() {
            allQueryStatusUpdaters.finish(queryId);
        },
        abort() {
            allQueryStatusUpdaters.abort(queryId);
        },
    }), [allQueryStatusUpdaters, queryId]);
}
interface QueryStatusUpdaters {
    start(trigger: "periodic" | "other"): void;
    error(error: Error): void;
    finish(): void;
    abort(): void;
}
interface AllQueryStatuses {
    [queryId: string]: QueryStatus;
}
type QueryStatus = {
    isLoading: true;
    trigger: "periodic" | "other";
    error: null;
} | {
    isLoading: false;
    trigger: null;
    error: Error | null;
};
const allQueryStatusesContext = createContext<{
    allQueryStatuses: AllQueryStatuses;
    allQueryStatusUpdaters: AllQueryStatusUpdaters;
} | null>(null);
function useAllQueryStatuses(): {
    allQueryStatuses: AllQueryStatuses;
    allQueryStatusUpdaters: AllQueryStatusUpdaters;
} {
    const [allQueryStatuses, setAllQueryStatuses] = useState<AllQueryStatuses>({});
    const allQueryStatusUpdaters: AllQueryStatusUpdaters = useMemo(() => {
        return {
            start(queryId: string, trigger: "periodic" | "other") {
                setAllQueryStatuses((prev) => ({
                    ...prev,
                    [queryId]: { isLoading: true, trigger, error: null },
                }));
            },
            error(queryId: string, error: Error) {
                setAllQueryStatuses((prev) => ({
                    ...prev,
                    [queryId]: { isLoading: false, error, trigger: null },
                }));
            },
            finish(queryId: string) {
                setAllQueryStatuses((prev) => ({
                    ...prev,
                    [queryId]: { isLoading: false, error: null, trigger: null },
                }));
            },
            abort(queryId: string) {
                setAllQueryStatuses((prev) => Object.fromEntries(Object.entries(prev).filter(([id]) => id !== queryId)));
            },
        };
    }, []);
    return { allQueryStatuses, allQueryStatusUpdaters };
}
interface AllQueryStatusUpdaters {
    start(queryId: string, trigger: "periodic" | "other"): void;
    error(queryId: string, error: Error): void;
    finish(queryId: string): void;
    abort(queryId: string): void;
}
function useAllQueryStatusContext() {
    const contextValue = useContext(allQueryStatusesContext);
    if (contextValue === null) {
        throw new Error("QueryContextProvider cannot be found. Ensure you are in the context of a QueryContextProvider, or use the `useInlineStatusQuery` hook instead.");
    }
    return contextValue;
}
const queryCorrelationContext = createContext<RequestCorrelationContext>({});
// This is not a required context
// If we are executing a useInlineStatusQuery, we might be operating somewhere that hasn't supplied a QueryContext (e.g. the SpaceSwitcher)
// In this case, there is no useful ambient context to provide, so we can safely use the default value from the context `{}`
export function useQueryCorrelationContext() {
    return useContext(queryCorrelationContext);
}
