import type { RequestCorrelationContext } from "@octopusdeploy/octopus-server-client";
import type { ReactNode } from "react";
import React from "react";
import { MutationContextProvider, useAggregateMutationsState } from "./mutations/MutationContext";
import { QueryContextProvider, useAggregateQueryStatuses } from "./query/QueryContext";
export function APIOperationStatusProvider({ children, correlationContext }: {
    children: ReactNode;
    correlationContext: RequestCorrelationContext;
}) {
    return (<QueryContextProvider correlationContext={correlationContext}>
            <MutationContextProvider correlationContext={correlationContext}>{children}</MutationContextProvider>
        </QueryContextProvider>);
}
export function useAggregateAPIOperationStatus() {
    const { errors: mutationErrors, isExecutingAnyMutation } = useAggregateMutationsState();
    const { errors: queryErrors, isLoadingAnyQuery } = useAggregateQueryStatuses();
    return {
        isInProgress: isLoadingAnyQuery || isExecutingAnyMutation,
        errors: [...mutationErrors, ...queryErrors],
    };
}
